.sidebar .sidebar-brand-wrapper .sidebar-brand.brand-logo-mini img {
  width: calc(90px - 50px);
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
  width: calc(90px - 50px);
}
.userlistss tbody tr td {
  padding: 0px 12px;
}
.form-control:focus {
  color: #fff !important;
  border: 1px solid rgb(147 130 177 / 61%) !important;
}
/* .asrt-page-length select{
    background: transparent !important;
    border: 1px solid #413e47 !important;
} */
select {
  cursor: pointer;
}

select option {
  cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer !important;
  color: #fff !important;
}

.asrt-pagination li {
  cursor: pointer;
}

.passwordinput .form-control {
  background-color: #191c24;
}

.passwordinput .btn-outline-primary {
  color: #6e7ee5;
  border-color: #494a51;
  padding: 10px 10px;
}

.passwordinput .btn-outline-primary:hover {
  background-color: transparent !important;
}
.footer a {
  color: #886abe !important;
}
.asrt-page-length .input-group-addon {
  background: unset !important;
}
.asrt-page-length select {
  color: #fff !important;
}
.input-group-prepend span {
  color: #fff !important;
}
.asrt-table-foot {
  margin-top: 2%;
}
.page-item.disabled .page-link {
  color: #9382b1 !important;
  pointer-events: none;
  cursor: auto;
  background-color: #fff0 !important;
  border-color: #9382b1 !important;
}
.page-item.disabled .page-link:hover {
  background-color: #886abe !important;
  color: #fff !important;
}
.as-react-table .pagination li:hover a {
  background-color: #886abe !important;
  color: #fff !important;
}
.page-link {
  background-color: #00000000 !important;
  border: 1px solid #9382b1;
}
.page-link input {
  background: unset !important;
  color: #fff !important;
}
.allbtn {
  background: #886abe;
  border: 1px solid #886abe;
  padding: 10px 16px;
}

.table thead th {
  line-height: 21px;
}
.allbtnlogout {
  background: red;
  border: 1px solid red;
  padding: 6px 14px;
  color: #fff;
  border-radius: 6px;
}
.allbtnlogout:hover {
  background: transparent;
  border: 1px solid red;
  color: red;
}
.allbtns {
  padding: 8px 16px !important;
}
.css-yt9ioa-option {
  color: black !important;
  /* background-color: #886abe !important; */
}
.css-1n7v3ny-option {
  /* background-color: #886abe !important; */
}

.NFT.IDT {
  width: 580px !important;
  max-width: 580px !important;
  word-wrap: break-word !important;
}
.NFT.IDT p {
  line-height: normal;
}

.css-6c09z3-option {
  color: #000 !important;
}
.css-1gj9k8-option {
  background-color: unset !important;
  color: #000 !important;
}
.css-zjsb6u-option {
  background-color: #886abe !important;
}

.css-1n7v3ny-option {
  background-color: unset !important;
  color: black !important;
  /* background-color: transparent !important;
   color: black !important; */
}
.css-1pahdxg-control:hover {
  border-color: white !important;
}
.css-9gakcf-option {
  background-color: #886abe !important;
}
/* .css-9gakcf-option {
    background-color: transparent !important;
    color: black !important;
} */

.allbtn:hover {
  background: transparent;
  border: 1px solid #886abe;
  color: #886abe !important;
}
.icon.icon-box-success {
  background: rgb(130 101 182 / 24%) !important;
}
.icon .icon-item {
  color: #9768ec;
}
.ReactModal__Overlay {
  background-color: #00000091 !important;
}
.ReactModal__Content {
  border: 1px solid #808080a8 !important;
  background: rgb(11 12 15) !important;
  border-radius: 16px !important;
}
.ReactModal__Content h2 {
  color: rgb(136 106 190) !important;
}
.closebtn {
  position: absolute;
  right: 8px;
  top: 4px;
  font-size: 19px;
  font-weight: 500;
}
.closebtn:hover {
  color: #886abe;
}
.table-responsive {
  padding-bottom: 2%;
  overflow-x: auto !important;
}
/* .asrt-table-body {
    overflow: auto;
    padding-bottom: 2%;
} */
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.table-responsive::-webkit-scrollbar {
  width: 8px !important;
  height: 9px !important;
  background-color: #f5f5f5;
}

.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #886abe;
}
.faq .as-react-table {
  padding-bottom: 2%;
  overflow-x: auto !important;
}
.faq .as-react-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.faq .as-react-table::-webkit-scrollbar {
  width: 8px !important;
  height: 9px !important;
  background-color: #f5f5f5;
}

.faq .as-react-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #886abe;
}
.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  display: none;
}
.dash-token {
  color: #886abe !important;
}
.editservice {
  font-size: 15px;
}
.sidebar .nav.sub-menu .nav-item .nav-link {
  white-space: normal;
  line-height: 20px;
}
/* th.sortable {
    color: #886abe !important;
    font-weight: 600 !important;
} */

@media only screen and (max-width: 767px) {
  .table-responsive::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    background-color: #f5f5f5;
  }
  .faq .as-react-table::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    background-color: #f5f5f5;
  }
  .table-responsive {
    padding-bottom: 8%;
  }
  .faq .as-react-table {
    padding-bottom: 8%;
  }
  .pagination {
    justify-content: unset !important;
  }
  .pagination li {
    font-size: 11px !important;
    margin-top: 10px;
  }
  .table_filter input {
    margin-top: 14px;
  }
  .asrt-page-length .input-group-text {
    font-size: 11px;
  }
  .asrt-page-length select.form-control {
    padding: 0.4375rem 0.45rem !important;
    width: 53px !important;
    font-size: 12px !important;
  }
  .asrt-table-head .text-right {
    text-align: left !important;
  }
  .asrt-table-foot > div:first-child {
    font-size: 12px;
    margin-top: 6px;
  }
}
.navbar.loginnavbar {
  left: 0px !important;
}
.btn-primary:not(.btn-light):not(.btn-secondary):focus,
.custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):focus:after,
.btn-primary:not(.btn-light):not(.btn-secondary):active,
.custom-file
  .custom-file-label:not(.btn-light):not(.btn-secondary):active:after {
  background: #886abe !important;
  border-color: #886abe !important;
  color: #fff !important;
}

.referralpages .btnsec {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.referralpages .add_btnsec {
  margin-left: 15px;
  margin-top: 10px;
}
.referralpages .allbtn {
  width: 100px;
}

@media only screen and (max-width: 767px) {
  .referralpages .add_btnsec {
    margin-left: 12px;
  }
  .referralpages .btnsec {
    margin-bottom: 15px;
  }
}

/* start of hariharan css */

.custom_react_select .css-1okebmr-indicatorSeparator {
  display: none;
}

.custom_react_select svg {
  color: white !important;
}
.custom_modal_input {
  border: 1px solid #5a5869;
  height: calc(2.25rem + 2px);
  font-weight: normal;
  font-size: 0.875rem;
  padding: 0.625rem 0.6875rem;
  background-color: #28253f;
  border-radius: 2px;
  color: #ffffff;
}

/* start of react datepicker */

.cust_rct_date_pck input {
  background: #28253f;
  border-radius: 0.375rem;
  border-radius: 5px;
  height: 45px;
  color: white;
  border: 1px solid #5a5869;
  outline: 0px;
  padding-left: 10px;
  padding-right: 10px;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}
/* end of react datepicker */

.allbtn {
  background: #00ef9b;
  border: 1px solid #00ef9b;
  padding: 10px 16px;
  color: #18152d;
  border-radius: 15px;
}
.allbtn:hover {
  background: transparent;
  border: 1px solid #00ef9b;
  color: #00ef9b !important ;
}
.card.corona-gradient-card {
  background: #00ef9b !important;
}
.navbar {
  background: #0d0b18;
}
.sidebar {
  background: #0d0b18;
}
.sidebar .sidebar-brand-wrapper {
  background: #0d0b18;
}
.sidebar .nav .nav-item.active > .nav-link {
  background-color: #302d4d;
}
.card {
  background: #0d0b18;
}
.dash-token {
  color: #00ef9b !important ;
}
.icon.icon-box-success {
  background: #00ef9b !important;
}
.icon .icon-item {
  color: #0d0b18 !important;
}
.ReactModal__Content h2 {
  color: #00ef9b !important ;
}
.sidebar .nav .nav-item .nav-link:hover {
  background-color: #302d4d;
}
.table thead th {
  border-color: #302d4d !important;
}
.table {
  color: white;
}
.sidebar
  .nav:not(.sub-menu)
  > .nav-item:hover:not(.nav-category):not(.account-dropdown)
  > .nav-link {
  background-color: #302d4d !important;
}
.sidebar .nav .nav-item.active > .nav-link:before {
  background-color: #00ef9b;
}
th.sortable {
  color: #00ef9b !important;
  /* color: #28253f; */
}
.table thead th {
  color: #00ef9b !important;
}
.form-control {
  background: #28253f;
  border-color: #5a5869;
  border-radius: 0.375rem;
}
.form-control:focus {
  background: #28253f !important;
}
.asrt-pagination li:hover {
}
.as-react-table .pagination li:hover a {
  background: #00ef9b !important;
}
.page-item.disabled .page-link {
  color: #00ef9b !important;
  border-color: #00ef9b !important;
}
.page-link {
  color: #00ef9b !important;
  border-color: #00ef9b !important;
}
.page-link input {
  color: #00ef9b !important;
}
.page-item:hover input {
  color: #fff !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #414141;
  opacity: 1;
}
a {
  color: #00ef9b;
}
.navbar .navbar-brand-wrapper {
  background: transparent !important;
}
.hds_mob_logo {
  width: 100% !important;
  height: auto !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #28253f !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00ef9b !important;
}

.admin_btn {
  padding: 2px 10px;
  color: #18152d;
  border-radius: 5px;
  font-size: 14px;
}

.adm_eye_icn {
  width: 20px !important;
}
.adm_pfl_img_wrp img {
  margin-left: 55px;
}
.adm_cvr_img_wrp img {
  margin-left: 17px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */

.adm_signin_inpt {
  border-radius: 0.375rem !important;
  padding: 10px 11px !important;
  max-height: 38px !important;
}
.adm_singin_pass_inpt {
  background: none !important;
  border: none !important;
  max-height: 36px !important;
}
.adm_signin_eye_btn {
  border-color: #5a5869 !important;
  border-radius: 0.375rem !important;
  border: none !important;
}
.adm_sigin_passwordinpt {
  border: 1px solid #5a5869 !important;
  max-height: 38px !important;
  border-radius: 0.375rem !important;
}
.adm_singin_pass_inpt:focus {
  border: none !important;
}

.react_select_admin__menu {
  background: #28253f !important;
  border: 1px solid #5a5869;
}
.react_select_admin__option:active {
  background-color: #00ef9b !important;
}
.react_select_admin__indicator-separator {
  display: none !important;
}

.navbar-brand.brand-logo-mini.eco_brand_log_mini {
  width: 58px;
}
/* end of hariharan css */
